import { CreditCard } from '@styled-icons/fa-solid';
import { Icon, IconProps } from './Icon';

export function CreditCardIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <CreditCard />
    </Icon>
  );
}
